@use "@angular/material" as mat;

@mixin dottnet-dialog-component-theme($theme) {
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);

	fa-icon {
		color: rgba(0, 0, 0, 0.6);
	}
}
