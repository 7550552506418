@mixin getMediaFor($bp) {
    @media #{map-get($flex-media-queries, $bp)} {
        @content;
    }
}

$dottnet-primary: rgb(134, 134, 134);
$dottnet-accent: #064e9b;
$xs: 599px;
$sm: 959px;
$md: 1279px;
$lg: 1919px;
$xl: 4000px;
$flex-media-queries: ( 'xs': '(max-width: #{$xs})', 'sm': '(min-width:#{$xs + 1}) and (max-width: #{$sm})', 'md': '(min-width:#{$sm + 1}) and (max-width: #{$md})', 'lg': '(min-width:#{$md + 1}) and (max-width: #{$lg})', 'xl': '(min-width:#{$lg + 1}) and (max-width: #{$xl})', 'lt-sm': '(max-width: #{$xs})', 'lt-md': '(max-width:#{$sm})', 'lt-lg': '(max-width:#{$md})', 'lt-xl': '(max-width:#{$lg})', 'gt-xs': '(min-width: #{$xs + 1})', 'gt-sm': '(min-width: #{$sm + 1})', 'gt-md': '(min-width: #{$md + 1})', 'gt-lg': '(min-width: #{$lg + 1})');