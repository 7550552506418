@import '@angular/material/theming';
@mixin dottnet-toolbar-component-theme($theme) {
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);

	mat-toolbar {
		border-bottom: 1px solid mat.get-color-from-palette($primary, darker);
		background-color: mat.get-color-from-palette($primary, lighter);

		button {
			&.active {
				color: mat.get-color-from-palette($accent, default-contrast);
				background-color: mat.get-color-from-palette($accent);
			}
		}

		.link {
			color: mat.get-color-from-palette($primary, default-contrast);
		}

		.menu-hr {
			border: 1.5px solid mat.get-color-from-palette($primary, darker);
		}

		mat-toolbar {
			border-bottom: 1px solid mat.get-color-from-palette($primary, darker);
		}
	}
}
