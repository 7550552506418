@import '@angular/material/theming';
@mixin dottnet-footer-component-theme($theme) {
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);

	.footer {
		color: mat.get-color-from-palette($primary, default-contrast);
		background-color: mat.get-color-from-palette($primary, lighter);

		.links,
		.signature {
			a {
				color: mat.get-color-from-palette($primary, lighter);
				&:hover {
					color: mat.get-color-from-palette($accent);
				}
			}
		}
	}
}
