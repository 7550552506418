@import 'styles-variables.scss';
*:not(img) {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    min-height: 100%;
}

html {
    height: 100%;
    width: 100%;
    //  scroll-behavior: smooth;
}

body {
    min-height: 100%;
    width: 100%;
}


/* STATIC.CSS */

// .redazione-column-dx {
// 	margin-left: 300px;
// }
// .redazione-column-sx {
// 	margin-right: 60%;
// }
// pagine statiche
.static-text-justify {
    text-align: justify;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}

#static-condizioni p {
    font-size: 16px;
}

h1,
h2 {
    margin: 0px;
}

#static-condizioni-extra h1 {
    font-size: 36px;
}

#static-condizioni-extra p {
    white-space: normal;
}

#static-condizioni-extra ol {
    font-size: 16px;
    li {
        margin-top: 15px;
    }
    p {
        font-size: 16px;
        margin: 0px;
    }
}

#static-condizioni-extra {
    .titolo1 {
        color: #0e7bbb;
    }
    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .text-left {
        text-align: left;
    }
    .extra-paragraph {
        margin-left: -40px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.chi-siamo ul {
    margin-bottom: 25px;
}

.chi-siamo {
    margin-top: 6px;
    font-family: 'Lato', sans-serif;
}

.redazione-column-dx span {
    font-size: 12px;
}

.chi-siamo h2 {
    color: #4d4d4d;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
}

.chi-siamo h3 {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #4d4d4d;
    font-size: 14px;
    font-family: Lato;
    font-weight: 500;
}

.chi-siamo .label-primary {
    font-size: 13px;
    background-color: #337ab7;
}

.chi-siamo .label {
    font-size: 80%;
    font-family: Lato;
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.chi-siamo-job {
    margin-top: 8px;
    border-left: 1px solid #f2f2f2;
}

.chi-siamo-job li {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    list-style-type: none;
    a {
        color: #337ab7;
        text-decoration: none;
    }
}

.chi-siamo-margin {
    margin-top: 5px;
    margin-bottom: 5px;
}

.chi-siamo-margin-top {
    margin-top: 5px;
}

.chi-siamo-margin-bottom {
    margin-bottom: 5px;
}

.laurea {
    color: #666;
    font-style: italic;
}

.titolo-static {
    font-size: 18px;
    font-weight: bold;
    color: #4d4d4d;
}

@media only screen and (max-width: 1200px) {}


/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {}


/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
    .chi-siamo {
        margin-left: 0px;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {}


/* FINE STATIC.CSS */

.scrollable {
    scroll-margin-top: 105px;
}

#transition-popper {
    z-index: 1 !important;
}

.non-selectable {
    user-select: none;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-underline-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}

// Global-Classes
.full-width {
    width: 100%;
}

.large-field {
    width: 300px;
}

.lato-text {
    font-family: 'Lato', sans-serif;
}

// Lots of widget css coming from BE has z-index at 100
dottnet-toolbar {
    z-index: 101;
}

// Flex Classes
// BEWARE class order counts: always add those classes before the component class
// Example -> before adding flex classes: 'top-rubriche'
//		   -> after adding flex classes: 'flex-row top-rubriche'
// ^ This behaviour permits component styles to overwrite global flex classes ^
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

// fxFill
.flex-fill {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
}

//fxFlex='100'
.flex-row-100 {
    flex: 1 1 100%;
    max-width: 100%;
}

.flex-row-50 {
    flex: 1 1 100%;
    max-width: 50%;
}

.flex-row-33 {
    flex: 1 1 100%;
    max-width: 33%;
}

.flex-row-25 {
    flex: 1 1 100%;
    max-width: 25%;
}

.flex-col-100 {
    flex: 1 1 100%;
    max-height: 100%;
}

.flex-col-50 {
    flex: 1 1 100%;
    max-height: 50%;
}

.flex-col-25 {
    flex: 1 1 100%;
    max-height: 25%;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-start {
    justify-content: flex-start;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-justify-space-between {
    justify-content: space-between;
}

.flex-justify-space-around {
    justify-content: space-around;
}

.flex-justify-space-evenly {
    justify-content: space-evenly;
}

.flex-align-center {
    align-items: center;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-align-base {
    align-items: baseline;
}

.flex-align-end {
    align-items: flex-end;
}

// fxFlex
.flex-basis-auto {
    flex: 1 1 0%;
}

// Form
.form-container {
    position: relative;
    margin-top: 10px;
}

// Icons
.dn-icon {
    font-size: 25px;
    cursor: pointer;
}

// Snackbar
.success-notification-overlay,
.error-notification-overlay,
.info-notification-overlay {
    font-family: Lato, 'sans-serif';
    background-color: white;
    // Important needed to overwrite MatSnackbar
    margin-bottom: 100px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    button {
        width: 100%;
        color: black !important;
    }
}

@include getMediaFor('xs') {
    .success-notification-overlay,
    .error-notification-overlay,
    .info-notification-overlay {
        width: 100%;
    }
}

.error-notification-overlay {
    border: 3px solid #d32f2f;
    button {
        background-color: #ef5350;
    }
}

.success-notification-overlay {
    border: 3px solid #376e37;
    button {
        background-color: #5cb85c;
    }
}

// Spinner
.form-spinner {
    position: absolute;
    top: -5px;
    right: -5px;
    left: -5px;
    bottom: -5px;
    background: rgba(0, 0, 0, 0.05);
    // background: radial-gradient(2000px, transparent, rgba(0, 0, 0, 0.9));
    // border-image: linear-gradient(100px, black, transparent);
    box-shadow: 0 0 180px 3px white inset;
    // box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

// Buttons
.button-group>button {
    margin: 0 5px;
}

// Titles
// h1, h2, h3, h4 {
// font-family: Lato;
// }
h1 {
    // text-align: center;
    font-size: 3em;
}

h2 {
    font-size: 1.5em;
    font-family: 'Lato', sans-serif;
}

h3 {
    font-weight: bold;
}

h4,
h5 {
    font-family: 'Lato', sans-serif;
}

// Do not select eyelet to not get flickering in SSR
p:not(.eyelet) {
    font-size: 18px;
    line-height: 1.42857143;
    // Keeps escaped characters coming from translation files
    // E.G \n and similia
    white-space: pre-line;
}

dottnet-legacy-dynamic-container {
    p {
        white-space: normal !important;
    }
}

// Dottnet styles
.fullsize-file-viewer>#pdfWrapper {
    height: 100vh !important;
    margin-bottom: 3rem;
}

@include getMediaFor('lt-lg') {
    .fullsize-file-viewer>#pdfWrapper {
        margin-bottom: 1rem;
    }
}

.dottnet-menu {
    border: 1px solid rgba(77, 77, 77, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dottnet-content-title {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    padding-bottom: 20px;
    margin: 30px 8px 20px;
    text-transform: uppercase;
    border-bottom: 3px solid #e6e6e6;
    text-align: left;
    color: #000;
    letter-spacing: 0.4px;
    font-weight: 500;
    line-height: 1.1;
}

.float-left {
    float: left;
}

.file-wrapper {
    width: 100%;
}

.header {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    margin-bottom: 1rem;
    a {
        color: #b90007;
        text-decoration: none;
    }
}

.header-margin {
    margin-bottom: 20px;
}

.dottnet-title {
    font-size: 35px;
    font-weight: 600;
    font-family: 'Source Serif Pro';
    color: #000;
    letter-spacing: -0.5px;
}

.eyelet {
    color: #4d4d4d;
    display: initial;
    font-family: 'Source Serif Pro';
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
}

.dottnet-rubrica {
    padding: 0 1rem;
}

// Text
.warn-info {
    color: #e53935;
}

// Material overrides
// comment bubbles start
.commentBox {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

// comment bubbles end
// Extra Premium styles start
#formPagamento .mat-mdc-tab-list {
    color: #003159;
}

#formPagamento .mat-mdc-tab-group {
    min-width: 40%;
}

@include getMediaFor('xs') {
    #formPagamento .mat-mdc-tab-group {
        min-width: 100%;
    }
}

#formPagamento .mdc-tab-indicator .mdc-tab-indicator__content {
    color: #003159;
}

.payment-form {
    .mdc-form-field {
        label {
            font-size: 85%;
        }
        flex-direction: column;
    }
}

.extra-prosegui-button {
    .mdc-button__label {
        color: white;
    }
    .mat-mdc-button {
        background-color: #337ab7;
        border-radius: 20px;
        width: 170px;
    }
    .mat-mdc-button:disabled {
        opacity: 0.5;
    }
}

.dati-fattura {
    .mat-mdc-form-field-error {
        font-size: 11px;
    }
}

#formPagamento {
    .mat-mdc-form-field-error {
        font-size: 11px;
        text-align: end;
        margin-right: 5px;
    }
}

.codice-sconto,
.carica-anagrafica,
.genera-coupon,
.disdici {
    .mdc-button__label {
        color: white;
    }
    .mat-mdc-button {
        background-color: #e8b909;
        border-radius: 20px;
        width: 150px;
        margin-bottom: 10px;
    }
    .mat-mdc-button:disabled {
        opacity: 0.5;
    }
}

.disdici {
    .mat-mdc-button {
        width: 200px;
    }
    .mdc-button__label {
        font-size: 13px;
    }
}

.genera-coupon {
    .mdc-button__label {
        font-size: 11px;
    }
}

.codice-sconto,
.carica-anagrafica,
.genera-coupon {
    .mdc-text-field {
        font-size: 11px;
        max-height: 50px;
    }
    .mat-mdc-form-field-error {
        font-size: 11px;
    }
    .mat-mdc-form-field-bottom-align::before {
        height: 0px;
    }
}

.total-pay {
    .mat-mdc-checkbox {
        margin-right: 10px;
    }
}

.dati-fattura {
    .mdc-text-field {
        font-size: 11px;
        border-radius: 10px;
    }
}

.dichiarazione {
    font-size: 11px;
    text-align: left;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

@media only screen and (min-width: $sm) {
    .login-container {
        .form-signin-extra {
            .signin-container {
                .form-container {
                    .input-login {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 18px !important;
                    }
                    .form-wrapper {
                        gap: 0px;
                    }
                }
            }
        }
    }
}

//extra page style of elements loaded from language json
.extra-base {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.42857143;
}

.extra-base ul li {
    min-height: initial;
    display: block;
    min-height: initial;
    display: block;
    background: url('assets/img_content/check.png') no-repeat;
    margin-left: 0;
    padding: 0 0 5px 40px;
    line-height: 2;
}

.extra-base p {
    padding: 6px 0;
    margin: 0 0 10px;
    font-size: 16px;
}

.extra-base h1 {
    font-size: 32px;
    font-weight: bold;
}

@include getMediaFor('xs') {
    #extra-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        justify-content: center;
    }
    .extra-base h1 {
        font-size: 17px;
        text-align: center;
    }
}

// Extra Premium styles end
// Label takes full width | removed material style -> transform: translateY(106%) scale(0.75)
.mdc-text-field--filled .mdc-floating-label--float-above {
    width: 100%;
    transform: translateY(-150%) !important;
    font-size: 12px;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
    font-weight: bold !important;
    font-size: 14px !important;
    color: #4d4d4d;
}

// .rootLink .mdc-list-item__content {
//     opacity: 1 !important;
// }
// .rootLink .mdc-list-item__primary-text {
//     color: $dottnet-accent !important;
// }
.mdc-list-item__content {
    font-family: 'Lato', sans-serif;
}

.mat-mdc-text-field-wrapper {
    flex: 0 1 auto !important;
}

.mat-mdc-form-field-infix {
    padding-bottom: 0 !important;
}

// .mat-mdc-form-field-input-control {
//     height: 30px !important;
// }
mat-hint {
    color: $dottnet-primary;
}

mat-card-content {
    padding: 0 !important;
}

mat-card-title-group {
    width: inherit !important;
    max-width: 100%;
}

mat-card-subtitle {
    font-family: Roboto, Helvetica Neue, sans-serif;
}

.mat-mdc-card-subtitle {
    margin-bottom: 12px !important;
}

.mat-mdc-card-title-group {
    min-height: 0;
}

.mat-drawer-container {
    // Needed to make position: sticky work on toolbar
    overflow: visible !important;
    // Needed to make Dottnet full width
    width: 100%;
}

.mat-drawer-content {
    // Needed to make position: sticky work on toolbar
    overflow: visible !important;
}

.mat-mdc-menu-panel {
    border-radius: 0 !important;
    margin-top: -2px;
}

.mat-mdc-menu-content {
    padding: 0 !important;
}

.mat-mdc-input-element:disabled {
    color: darkgrey;
}

// Dialogs
// Switch visualization between mobile and desktop title row for dialogs
div[mat-dialog-title].desktop-dialog {
    display: flex;
    @include getMediaFor('lt-lg') {
        display: none;
    }
}

div[mat-dialog-title].mobile-dialog {
    display: flex;
    @include getMediaFor('gt-md') {
        display: none;
    }
}

// setto al 100% della dialog content le immagini presenti nel corpo degli articoli
.mat-mdc-dialog-content .corpo img {
    width: 100%;
}

.dialog-body-wrapper {
    padding: 0.5rem 1rem;
}

.dialog-header {
    gap: 10px;
}

.dialog-title {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
    user-select: none;
    color: #0e4482;
}

.dialog-subtitle {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 16px;
    user-select: none;
    color: #337ab7;
}

.dialog-title-separator {
    width: 90%;
    background-color: #b3b2b2;
    margin: 0 auto;
}

.dn-dialog-large {
    height: 92%;
}

.dn-dialog-large,
.dn-dialog-medium,
.dn-dialog-small {
    font-family: 'Lato', sans-serif;
    width: 72%;
    #dialogLogoDN {
        height: 60px;
        cursor: pointer;
    }
    .mat-mdc-dialog-content {
        height: 100%;
        max-height: none;
        width: 100%;
        margin: 0;
    }
    dottnet-file-reader {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .mdc-dialog__title {
        padding: 0.5rem;
    }
    .mdc-dialog__title::before {
        content: none;
    }
    .mat-mdc-dialog-container .mdc-dialog__content {
        color: inherit;
    }
}

.dn-dialog-small {
    width: 40vw;
    // height: 65vh;
}

.dn-dialog-medium {
    width: 50vw;
    // height: 80vh;
    .mat-mdc-dialog-content {
        max-height: 75vh;
    }
}

.svg-inline--fa {
    vertical-align: -0.125em;
}

fa-icon svg {
    display: inline-block;
    font-size: inherit;
    height: 3em;
}

fa-icon .fa-3x {
    height: 3em;
}

@include getMediaFor('xs') {
    .dn-dialog-large,
    .dn-dialog-medium,
    .dn-dialog-small {
        max-width: 100vw !important;
        max-height: 100vh;
        height: 100%;
        width: 100%;
    }
}

@include getMediaFor('gt-md') {
    .dn-dialog-large {
        max-width: 50vw !important;
    }
}

@include getMediaFor('xl') {
    .dn-dialog-large {
        max-width: 40vw !important;
    }
}
