@import '@angular/material/theming';
@mixin dottnet-sidenav-component-theme($theme) {
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);

	.branding {
		background-color: mat.get-color-from-palette($primary, lighter);
	}

	.branding a {
		color: mat.get-color-from-palette($accent);
	}

	.rootLink {
		color: mat.get-color-from-palette($accent);
	}
}
