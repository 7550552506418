@use "@angular/material" as mat;

@import '../shared/toolbar/toolbar.component.scss-theme';
@import '../shared/sidenav/sidenav.component.scss-theme';
@import '../shared/footer/footer.component.scss-theme';

@mixin dottnet-app-component-theme($theme) {
	@include dottnet-footer-component-theme($theme);
	@include dottnet-sidenav-component-theme($theme);
	@include dottnet-toolbar-component-theme($theme);

	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);

	mat-sidenav {
		background-color: mat.get-color-from-palette($primary, lighter);
		border-right: 1px solid rgba(21, 101, 192, 0.5);
	}

	.success-notification-overlay,
	.info-notification-overlay,
	.error-notification-overlay {
		color: rgba(0, 0, 0, 0.8);
		background-color: mat.get-color-from-palette($primary, lighter);

		border: 3px solid mat.get-color-from-palette($accent, darker);

		button {
			background-color: mat.get-color-from-palette($accent, lighter);
		}
	}
}
