@use "@angular/material" as mat;

@mixin dottnet-user-form-component-theme($theme) {
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$warn: map-get($theme, warn);
	$foreground: map-get($theme, foreground);
	$background: map-get($theme, background);

	.form-group > mat-label,
	.form-group--row > mat-radio-group > mat-label {
		color: mat.get-color-from-palette($accent);
	}

	.form-group--radio-wrapper > mat-label {
		color: mat.get-color-from-palette($accent);
	}

	.form-hr {
		border: 1.5px solid mat.get-color-from-palette($primary, darker);
	}
}
